import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import React, {
    FC,
    ReactElement,
    SVGProps,
    useEffect,
    useRef,
    useState,
} from "react";

import IconProps from "./IconBaseProps";

export const IconBase = ({ url, icon, className }: IconProps): ReactElement => {
    const ImportedIconRef = useRef<FC<SVGProps<SVGSVGElement>> | any>(); //eslint-disable-line
    const [loading, setLoading] = useState(false);
    const appInsights = useAppInsightsContext();

    useEffect(() => {
        if (!icon) return;

        const importIcon = async () => {
            try {
                setLoading(true);
                const namedImport = await import(
                    /* webpackChunkName: "[request]" */
                    `icons/${icon}.svg`
                );
                ImportedIconRef.current =
                    namedImport.ReactComponent || namedImport.default;
            } catch (err) {
                process.env.NODE_ENV === "development" &&
                    console.warn(`Icon type ${String(icon)} is missing`);
                appInsights &&
                    appInsights !== null &&
                    appInsights.trackException({ exception: err as Error });
                return <></>;
            } finally {
                setLoading(false);
            }
        };
        importIcon();
        return () => {
            setLoading(false);
        };
    }, [icon, appInsights]);

    if (icon && !loading && ImportedIconRef.current) {
        if (typeof ImportedIconRef.current !== "string") {
            const { current: ImportedIcon } = ImportedIconRef;
            return <ImportedIcon className={className} />;
        } else {
            return <img src={ImportedIconRef.current} alt="" />;
        }
    }

    return !icon && url ? <img src={url} alt="" /> : <></>;
};

export default React.memo(IconBase);
